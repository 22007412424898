import './App.css';
import React, {useRef, useState, useEffect} from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { Box, Flex, Text, Center, useColorMode, VStack, Skeleton } from '@chakra-ui/react';
import { NavButton } from './components/menu/NavButton';
import { DevProjectsMenu } from './components/menu/DevProjectsMenu';
import { SrvProjectsMenu } from './components/menu/SrvProjectsMenu';
import { ToggleColorMode } from './components/menu/ToggleColorMode';
import { Home } from './page/Home';
import { JsDiscordBot } from './page/projects/js/JsDiscordBot';
import { JsWeb3Bot } from './page/projects/js/JsWeb3Bot';
import { Web3App } from './page/projects/js/Web3App';
import { PyScripts } from './page/projects/py/PyScripts';
import { DebianSelfHosted } from './page/projects/debian/DebianSelfHosted';
import { DebianVps } from './page/projects/debian/DebianVps';
import { Contact } from './page/Contact';
import { NotFound } from './page/NotFound';
import { MenuPhone } from './components/menu/MenuPhone';
import { PyDiscordBot } from './page/projects/py/PyDiscordBot';
import { PyMotiondetector } from './page/projects/py/PyMotionDetector';
import { GlossaryPage } from './page/glossary/GlossaryPage';
import { DefinitionPage } from './page/glossary/DefinitionPage';
import { SolLottery } from './page/projects/sol/SolLottery';
import { JsBackendApi } from './page/projects/js/JsBackendApi';
import {IoHome} from 'react-icons/io5'

function App() {

  const {colorMode} = useColorMode()
  const menuRef = useRef(null);
  const headerRef = useRef(null);
  const [headerH, setHeaderH] = useState('')
  const [menuW, setMenuW] = useState('')
  const [widthLoad, setWidthLoad] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);

    const isDesktopScreen = window.matchMedia("(min-width: 1024px)").matches;

    if (isDesktopScreen) {
      setWindowHeight(window.innerHeight);
    }

  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); 


  const getMenuWidth = () => {
    if (menuRef.current) {
       setMenuW(menuRef.current.offsetWidth);
    } else {
      setMenuW('')
    }
  };

  useEffect(() => {
    getMenuWidth()
    setWidthLoad(true)
  }, [windowWidth])

  const getHeaderHeight = () => {
    if (headerRef.current) {
      setHeaderH(headerRef.current.offsetHeight)
    } else {
      setHeaderH('')
    }
  }

  useEffect(() => {
    getHeaderHeight()
    setWidthLoad(true)
  }, [windowHeight])

  return (
    <Box>
      <Flex w='100%' maxW='100%' bg={colorMode === "dark" ? "#13181f" : "gray.100"} py={4} px={8} justify="space-between" align="center" alignItems='center' position={'fixed'} left={0} right={0} zIndex={999} ref={headerRef}>
        <Box bg={colorMode === "dark" ? "#13181f" : "gray.100"} display={{base: 'block', lg: 'none'}}>
          <MenuPhone />
        </Box>
        <Link to='/'>
          <Text fontSize='24px' ml='24px'>
            <IoHome />
          </Text>
        </Link>
        <Text className='font-2' fontSize="24px">My Portfolio</Text>
      </Flex>

      <Flex>
          <VStack bg={colorMode === "dark" ? "#13181f" : "gray.100"} 
                  py={16} px={4} 
                  overflowY="auto" 
                  spacing="0" 
                  position={'fixed'} 
                  minH={'full'} zIndex={998}
                  display={{ base: 'none', lg: 'flex' }}
                  alignItems='center' ref={menuRef}
          >
            <NavButton variant="navbutton" to="/">Home</NavButton>
            <DevProjectsMenu />
            <SrvProjectsMenu />
            <NavButton variant="navbutton" to="/glossary">Glossary</NavButton>
            <NavButton variant="navbutton" to="/contact">Contact</NavButton>
            <ToggleColorMode variant='themebutton'/>
          </VStack>

            <Center py={75} ml={`${menuW}px`} w={`calc(100% - ${menuW}px)`}>
              <Skeleton isLoaded={widthLoad} fadeDuration={2}>
                <Routes>
                  <Route path="/" element={<Home menuWidth={menuW} headerHeight={headerH} />} />
                  <Route path="/js-discord-bot" element={<JsDiscordBot />} />
                  <Route path="/js-web3-bot" element={<JsWeb3Bot />} />
                  <Route path="/js-web3-app" element={<Web3App />} />
                  <Route path="/js-api" element={<JsBackendApi />} />
                  <Route path="/sol-lottery" element={<SolLottery />} />
                  <Route path="/py-scripts" element={<PyScripts />} />
                  <Route path="/py-motion-detector" element={<PyMotiondetector />} />
                  <Route path="/py-discord-bot" element={<PyDiscordBot />} />
                  <Route path="/debian-self-hosted-server" element={<DebianSelfHosted />} />
                  <Route path="/debian-vps" element={<DebianVps />} />
                  <Route path="/glossary" element={<GlossaryPage />} />
                  <Route path="/glossary/:term" element={<DefinitionPage />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path='/*' element={<NotFound />} />
                </Routes>
              </Skeleton>
            </Center>

      </Flex>
    </Box>
  );
}

export default App;
