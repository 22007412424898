import { useState } from "react";
import { Tabs, Tab, TabList, TabPanel, TabPanels, TabIndicator, HStack, Text } from "@chakra-ui/react";

export const TabsFunction = ({ tabs, width, height, variant }) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (index) => {
        setActiveTab(index);
    };

    return (
        <Tabs zIndex={2} variant={variant} align='center' onChange={handleTabChange}>
            <TabList>
                {tabs.map((item, i) => (
                    <Tab key={i} w={width} h={height}>
                        <HStack>
                        <Text>{item.label}</Text>
                        </HStack>
                    </Tab>
                ))}
            </TabList>

            <TabIndicator
                mt="-1.5px"
                height="2px"
                bg="blue.500"
                borderRadius="1px"
            />

            <TabPanels>
                {tabs.map((item, i) => (
                <TabPanel key={i} p="0">
                    {item.content}
                </TabPanel>
                ))}
            </TabPanels>
        </Tabs>
    );
};
