import { Box, Center, Stack, Text, Divider, useColorMode, IconButton, useToast, Button, SimpleGrid, Badge } from "@chakra-ui/react";
import { ScrollToTopButton } from "../../../components/button/ScrollToTopButton";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark, solarizedlight } from "react-syntax-highlighter/dist/esm/styles/prism";
import { MdContentCopy } from "react-icons/md";
import { lotteryV1, lotteryV2 } from "../../../components/page-content/codepart/CodePartOfSol";
import { SkipNavLink, SkipNavContent } from '@chakra-ui/skip-nav'

export const SolLottery = () => {
    
    const { colorMode } = useColorMode();
    const toast = useToast({
        position: 'top',
    });

    const handleCopyClick = (text) => {
        navigator.clipboard.writeText(text);
        toast({
            title: "Success",
            description: "copied to clipboard",
            status: "success",
            duration: 3000,
            isClosable: true,
        });
    };

    return (
        <Box w={['375px', null, '650px', '750px', '1000px', '1250px']}>

            <Stack>
                <Center>
                    <Text className="font-2" fontSize='24px'>Lottery Smart-Contract</Text> 
                </Center> 
                <Divider />
            </Stack>

            <Stack>
                <Center mt='12px' display="flex" alignItems='center'>
                    You can check the repo

                    <Button variant="urlbutton" marginLeft="-0.7rem" as="a" href="https://github.com/dyfault-eth/lottery-smart-contract" target="_blank" rel="noreferrer">on github</Button> 

                    <Text marginLeft="-0.7rem">or on </Text>

                    <Button variant="urlbutton" marginLeft="-0.7rem" as="a" href="https://mumbai.polygonscan.com/address/0x6b0d05E25e2eC742D63C96bD9469714Df2Aaf3D0#code" target="_blank" rel="noreferrer">on polygonscan</Button>
                </Center>

                <Center>
                    <Divider w='300px' />
                </Center>

                <Center flex="1" flexDirection='column'>
                    Libraries :

                    <SimpleGrid columns={2} mt='6px' gap={2}>
                        <Badge colorScheme="green" variant='solid'>Hardhat</Badge>
                        <Badge colorScheme="green" variant='solid'>Chainlink</Badge>
                    </SimpleGrid>
                </Center>
            

                <Center>
                    <Divider w='950px' />
                </Center>
            </Stack>

            <Stack mt='16px'>
                <Center>
                    <Text> Project presentation: </Text> 
                </Center>

                <Text textAlign='center'>
                    This project is my first Solidity endeavor. 
                    It was executed in two major parts. Initially, 
                    the goal was to create a secure lottery system incorporating multiple players with equal chances of winning, employing a simple algorithm that isn't truly random. 
                    <br/><br/>
                    Once the first part was functional, the objective shifted to integrating a randomization algorithm. 
                    To achieve randomness on the blockchain, it's necessary to use an oracle, and for this purpose, 
                    Chainlink's VRF (Verifiable Random Function) emerged as the optimal choice for me. Currently, the draw is conducted manually by myself, but in the future, 
                    I aim to update my lottery with Chainlink's automation feature to trigger the draw automatically under certain conditions. 
                </Text>
            </Stack>

            <Center mt='24px'>
                <Button as={SkipNavLink} id='v2' href='v2' variant='skipnavbutton'>Skip to v2 code</Button>
            </Center>

            <Stack>
                <Box position='relative'>
                    <Center mt='24px' >
                        Lottery v1 code :
                    </Center>

                    <SyntaxHighlighter language="sol" style={ colorMode === 'dark' ? atomDark : solarizedlight } customStyle={{ fontSize: '14px'}}>{lotteryV1}</SyntaxHighlighter> 
                    <IconButton onClick = {() => handleCopyClick(lotteryV1)} position="absolute" top="63px" right="8px" size="sm" icon={<MdContentCopy />}/> 
                </Box>

                <SkipNavContent id='v2' />

                <Center>
                    <Divider w={['350px', null, '650px', '750px', '850px', '950px']} mt='24px' / >
                </Center>

                <Box position='relative'>
                    <Center mt='24px' >
                        Lottery v2 code :
                    </Center>

                    <SyntaxHighlighter language="sol" style={ colorMode === 'dark' ? atomDark : solarizedlight } customStyle={{ fontSize: '14px'}}>{lotteryV2}</SyntaxHighlighter> 
                    <IconButton onClick = {() => handleCopyClick(lotteryV2)} position="absolute" top="63px" right="8px" size="sm" icon={<MdContentCopy />}/> 
                </Box>
            </Stack>

            <ScrollToTopButton />

        </Box>
    )
}