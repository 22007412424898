import { Box, Center, useColorMode, useToast, Stack, Button, Divider, Text, IconButton, Image, SimpleGrid, Badge } from '@chakra-ui/react'
import { SkipNavLink, SkipNavContent } from '@chakra-ui/skip-nav';
import { ScrollToTopButton } from '../../../components/button/ScrollToTopButton'
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark, solarizedlight } from "react-syntax-highlighter/dist/esm/styles/prism";
import { MdContentCopy } from "react-icons/md";
import { pyMotionDetector } from '../../../components/page-content/codepart/CodePartOfMotionDetector';
import { TabsFunction } from '../../../components/page-content/TabsFunction';
import ReactMarkdown from 'react-markdown';
import 'github-markdown-css/github-markdown.css';
import { useState, useEffect } from 'react';

export const PyMotiondetector = () => {

    const [readmeContent, setReadmeContent] = useState('');
    const [gitCode, setGitCode] = useState('');
    const { colorMode } = useColorMode();
    const toast = useToast({
        position: 'top',
    });

    const handleCopyClick = (text) => {
        navigator.clipboard.writeText(text);
        toast({
            title: "Success",
            description: "copied to clipboard",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
    };

    useEffect(() => {
        fetch('https://raw.githubusercontent.com/dyfault-eth/py-human-detector/main/README.md')
          .then(response => response.text())
          .then(text => setReadmeContent(text))
          .catch(error => console.error(error));

        fetch('https://raw.githubusercontent.com/dyfault-eth/py-human-detector/main/main.py')
        .then(response => response.text())
        .then(text => setGitCode(text))
        .catch(error => console.error(error));
    }, []);

    const bashScript = `#!/bin/bash

while true
do

echo -e start motion detector

source ./venv/bin/activate
xvfb-run python3 ./main.py

deactivate

echo -e restarting motion detector in 5 seconds

sleep 5

done`

    const contentMotionDetector = 
    <Box>

        <Stack>
            <Center mt='12px' display="flex" alignItems='center'>
                You can check the project on{" "}
                
                <Button variant="urlbutton" marginLeft="-0.6rem" as="a" href="https://github.com/dyfault-eth/py-motion-detector" target="_blank" rel="noreferrer">my github</Button>
            </Center>
            <Center>
                <Divider w={['350px', null, '650px', '750px', '850px', '950px']}/ >
            </Center>

            <Center flex="1" flexDirection='column'>
                Libraries :
                
                <SimpleGrid columns={[3, null, 5]} mt='6px' gap={2}>
                    <Badge colorScheme="green" variant='solid'>opencv-python</Badge>
                    <Badge colorScheme="green" variant='solid'>smtp-lib</Badge>
                    <Badge colorScheme="green" variant='solid'>ssl</Badge>
                    <Badge colorScheme="green" variant='solid'>os</Badge>
                    <Badge colorScheme="green" variant='solid'>dotenv</Badge>
                </SimpleGrid>
            </Center>
            
            <Center>
                <Divider w='850px' />
            </Center>
        </Stack>

        <Box mt='16px' textAlign='center'>
            <Text>Project presentation : </Text>

            <Text mt='16px'>
                After experiencing a burglary and failing to quickly find security systems that met my needs, I made the decision to embark on creating my own system. <br/>
                To begin this security project, what could be more fitting than a motion detection camera?
                <br/><br/>
                PS: My code is meant to evolve and is currently not sufficiently optimized. Also, due to privacy reasons, I won't be providing image examples of motion detection."
            </Text>
        </Box>

        <Center>
            <SimpleGrid columns={{ base: 1, lg: 2 }} mt='24px' gap={ 2 } >
            <Button as={SkipNavLink} id='mail' href='mail' variant='skipnavbutton'>Skip to mail example</Button>

            <Button as={SkipNavLink} id='source-code' href='source-code' variant='skipnavbutton'>Skip to source code</Button> </SimpleGrid> 
        </Center>

        <Center>
            <Divider w={['350px', null, '650px', '750px', '850px', '950px']} mt='32px' / >
        </Center>

        <Box position='relative'>
            <Center mt='24px'>
                bash script to run the program :
            </Center>

            <SyntaxHighlighter language="bash" style={colorMode === 'dark' ? atomDark : solarizedlight} customStyle={{fontSize: '14px'}}>
                {bashScript}
            </SyntaxHighlighter>
            <IconButton onClick={() => handleCopyClick(bashScript)} position="absolute" top="40px" right="8px" size="sm" icon={<MdContentCopy />} />
        </Box>

        <SkipNavContent id='mail'/>

        <Center>
            <Divider w={['350px', null, '650px', '750px', '850px', '950px']} mt='32px' / >
        </Center>

        <Box position='relative'>
            <Center mt='32px'>
                mail example :
            </Center>

            <Center mt='8px'>
                <Image src='./images/py-motion-detector/mail.png' alt='exampke of mail the program send me when motion is detected' />
            </Center>
        </Box>

        <SkipNavContent id='source-code'/>

        <Center>
            <Divider w={['350px', null, '650px', '750px', '850px', '950px']} mt='32px' / >
        </Center>

        <Box position='relative'>
            <Center mt='32px'>
                python source code :
            </Center>

            <SyntaxHighlighter language="bash" style={colorMode === 'dark' ? atomDark : solarizedlight} customStyle={{fontSize: '14px'}}>
                {pyMotionDetector}
            </SyntaxHighlighter>
            <IconButton onClick={() => handleCopyClick(pyMotionDetector)} position="absolute" top="40px" right="8px" size="sm" icon={<MdContentCopy />} />
        </Box>

    </Box>

    const contentHumanDetector = 
    <Box>
        <Stack>
            <Center mt='12px' display="flex" alignItems='center'>
                You can check the project on{" "}
                
                <Button variant="urlbutton" marginLeft="-0.6rem" as="a" href="https://github.com/dyfault-eth/py-human-detector" target="_blank" rel="noreferrer">my github</Button>
            </Center>
            <Center>
                <Divider w={['350px', null, '650px', '750px', '850px', '950px']}/ >
            </Center>

            <Center flex="1" flexDirection='column'>
                Libraries :
                
                <SimpleGrid columns={[3, null, 4]} mt='6px' gap={2}>
                    <Badge colorScheme="green" variant='solid'>opencv-python</Badge>
                    <Badge colorScheme="green" variant='solid'>numpy</Badge>
                    <Badge colorScheme="green" variant='solid'>smtp-lib</Badge>
                    <Badge colorScheme="green" variant='solid'>ssl</Badge>
                    <Badge colorScheme="green" variant='solid'>os</Badge>
                    <Badge colorScheme="green" variant='solid'>time</Badge>
                    <Badge colorScheme="green" variant='solid'>datetime</Badge>
                    <Badge colorScheme="green" variant='solid'>dotenv</Badge>
                </SimpleGrid>
            </Center>
            
            <Center>
                <Divider w='850px' />
            </Center>
        </Stack>

        <Center>
            <SimpleGrid mt='24px'>
                <Button as={SkipNavLink} id='code' href='code' variant='skipnavbutton'>Skip to code</Button>
            </SimpleGrid>
        </Center>

        <Stack mt='16px'>
            <Box className='markdown-body' textAlign='left' bg={colorMode === 'dark' ? '#1a202c' : 'rgb(255, 255, 255)'} color={colorMode === 'dark' ? 'gray.200' : 'gray.500'}>
                <ReactMarkdown>{readmeContent}</ReactMarkdown>
            </Box>
        </Stack>

        <SkipNavContent id='code' />

        <Center>
            <Divider w={['350px', null, '650px', '750px', '850px', '950px']} mt='32px' / >
        </Center>

        <Box position='relative'>
            <Center mt='32px'>
                python source code :
            </Center>

            <SyntaxHighlighter language="bash" style={colorMode === 'dark' ? atomDark : solarizedlight} customStyle={{fontSize: '14px'}}>
                {gitCode}
            </SyntaxHighlighter>
            <IconButton onClick={() => handleCopyClick(gitCode)} position="absolute" top="40px" right="8px" size="sm" icon={<MdContentCopy />} />
        </Box>
    </Box>

    const tabs = [
    { label: "Motion Detector", content: contentMotionDetector },
    { label: "Human Detector", content: contentHumanDetector },
    ];

    return(
        <Center>
            <Box w={['375px', null, '650px', '750px', '1000px', '1250px']}>

                <Stack>
                    <Center>
                        <Text className="font-2" fontSize='24px'>Motion Detector</Text>
                    </Center>
                </Stack>

                <Box mt='12px'>
                    <TabsFunction tabs={tabs} />
                </Box>

            </Box>
            

            <ScrollToTopButton />
        </Center>
    );

}