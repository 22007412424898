import { Box, Button, Center, Divider, SimpleGrid, IconButton, Spinner } from "@chakra-ui/react";
import { Link } from 'react-router-dom';
import { useState, useEffect } from "react";
import { SmallCloseIcon } from "@chakra-ui/icons";

export const GlossaryPage = () => {

    const [selectedLetter, setSelectedLetter] = useState('');
    const [blockchainData, setBlockchainData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://api.dyfault.com/api/defs', {
                  headers: {
                    'Authorization': process.env.REACT_APP_API_TOKEN
                  }
                });
                const data = await response.json();
                setBlockchainData(data);

                setLoading(false);
            } catch (error) {
                console.error('Error fetching blockchain data:', error);
            }
        };

        fetchData();
    }, []);

    const alphabetButtons = 'abcdefghijklmnopqrstuvwxyz'.split('').map(letter => (
        <Button
            key={letter}
            onClick={() => setSelectedLetter(letter)}
            isActive={selectedLetter === letter}
        >
            {letter}
        </Button>
    ));

    const handleReset = () => {
        setSelectedLetter('');
    }

    let filteredBlockchainWords = [];

    if (blockchainData) {
        filteredBlockchainWords = blockchainData.filter(item => {
            return selectedLetter === '' || item.title.toLowerCase().startsWith(selectedLetter);
        });
    }

    return (
        <Box w={['375px', null, '650px', '750px', '1000px', '1250px']}>

        <Center>
            <SimpleGrid columns={[8, null, 13, null, 27]} gap={2}>
                <IconButton icon={<SmallCloseIcon />} onClick={handleReset} />
                {alphabetButtons}
            </SimpleGrid>
        </Center>

        {!loading ?
            <Box>
                <Box fontSize='24px' mt='16px'>Blockchain & Cryptocurrency</Box>
                <Divider />

                <SimpleGrid columns={[2, null, 4, 4, 6]} mt='10px' gap={2}>
                    {filteredBlockchainWords.map((item, index) => (
                        <Center key={index}>
                            <Link to={`/glossary/${item.id}`}>
                                <Button w='150px'>{item.title}</Button>
                            </Link>
                        </Center>
                    ))}
                </SimpleGrid>
            </Box>
        :
        <Center>
            <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
        </Center> }
        
    </Box>
    )
}