import { useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Button,
  Text,
  Input,
  Select,
  Center,
  Tag,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import { useEffect } from 'react';

const fetchCryptoList = async () => {
    try {
        const url = 'https://api.dyfault.com/api/crypto-list'
        const response = await fetch(url);
        const data = await response.json();
        return {url, data};
    } catch(e) {
        console.log(e);
    }
  };
  

export const AccordionApi = () => {
    const [cryptoId, setCryptoId] = useState('');
    const [currency, setCurrency] = useState('');
    const [cryptoIdsList, setCryptoIdsList] = useState();
    const [showList, setShowList] = useState(false);
    
    const [apiData, setApiData] = useState({
        cryptoPrice: null,
        fearData: null,
        btcData: null,
        flagTvlData: null,
        flagStakersData: null,
        flagRepartitionData: null,
    });
      

    const [inputs, setInputs] = useState({
        fearInput: 1,
        btcInput: 1,
        flagTvlInput: 1,
      });

    useEffect(() => {
        fetchCryptoList().then(data => setCryptoIdsList(data));
    }, []);

    const handleInputChange = (name, value) => {
        setInputs(prevInputs => ({
            ...prevInputs,
            [name]: value,
        }));
    };

    const handleDataChange = (name, value) => {
        setApiData(prevInputs => ({
            ...prevInputs,
            [name]: value,
        }));
    };

    const fetchData = async (url, options = {}, name) => {
        try {
          const response = await fetch(url, {
            headers: {
              'Content-Type': 'application/json',
              ...options.headers,
            },
            ...options,
          });
          const data = await response.json();
          handleDataChange(name, { url, data });
        } catch (error) {
          console.error('Error fetching data:', error);
          handleDataChange(name, { error });
        }
    };

    const handleFetchPrice = async () => {
        const url = `https://api.dyfault.com/api/price?id=${cryptoId}&currency=${currency}`;
        fetchData(url, {}, 'cryptoPrice');
    };

    const handleDisplayList = () => {
        setShowList(true);
    };

    const handleFetchFear = async () => {
        const url = `https://api.dyfault.com/api/fear-and-greed?limit=${inputs.fearInput}`;
        fetchData(url, {
            headers: { 'Authorization': process.env.REACT_APP_API_TOKEN },
          }, 'fearData');
    };

    const handleFetchBtcHist = async () => {
        const url = `https://api.dyfault.com/api/btc-price-history?limit=${inputs.btcInput}`;
        fetchData(url, {
            headers: { 'Authorization': process.env.REACT_APP_API_TOKEN },
          }, 'btcData');
    };

    const handleFetchFlagTvlHist = async () => {
        const url = `https://api.dyfault.com/api/flag-tvl?limit=${inputs.flagTvlInput}`;
        fetchData(url, {
            headers: { 'Authorization': process.env.REACT_APP_API_TOKEN },
          }, 'flagTvlData');
    };

    const handleFetchFlagStakers = async () => {
        const url = `https://api.dyfault.com/api/stakers-variation`;
        fetchData(url, {
            headers: { 'Authorization': process.env.REACT_APP_API_TOKEN },
          }, 'flagStakersData');
    };

    const handleFetchFlagRepartition = async () => {
        const url = `https://api.dyfault.com/api/tvl-variation`;
        fetchData(url, {
            headers: { 'Authorization': process.env.REACT_APP_API_TOKEN },
          }, 'flagRepartitionData');
    };

    return (
        <Accordion allowToggle>
            <AccordionItem w={['375px', null, '650px', '750px', '1000px', '1250px']}>
                <h2>
                    <AccordionButton>
                        <Center flex='1'>
                            Get Crypto Price
                        </Center>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>

                <AccordionPanel pb={4}>
                    <Center>
                        <Tag size='md' colorScheme='orange' borderRadius='full'>This endpoint doesn't need a token but there is a rate limit of 60 calls/minute</Tag>
                    </Center>

                    <Select mt={4} placeholder='Select Crypto' value={cryptoId} onChange={(e) => setCryptoId(e.target.value)}>
                        {cryptoIdsList ? cryptoIdsList.data.map((crypto) => (
                            <option key={crypto} value={crypto.id}>
                            {crypto.name}
                            </option>
                        )) : ""}
                    </Select>

                    <Select placeholder='Select Currency' mt={2} value={currency} onChange={(e) => setCurrency(e.target.value)}>
                        <option value='usd'>USD</option>
                        <option value='eur'>EUR</option>
                    </Select>

                    <Center>
                        <Button mt={2} onClick={handleFetchPrice}>Get Crypto Price</Button>
                    </Center>

                    {apiData.cryptoPrice && (
                        <Box mt={2}>
                            <Text>Request URL</Text>
                            <Input isReadOnly={true} value={apiData.cryptoPrice.url} />
                            <Text mt='8px'>Result</Text>
                            <Input isReadOnly={true} value={JSON.stringify(apiData.cryptoPrice.data, null, 1)} />
                        </Box>
                    )}
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
                <h2>
                    <AccordionButton>
                        <Center flex="1">
                            Get Crypto List
                        </Center>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>

                    <Center>
                        <Tag size='md' colorScheme='orange' borderRadius='full'>This endpoint doesn't need a token but there is a rate limit of 60 calls/minute</Tag>
                    </Center>

                    <Center mt={2}>
                        <Button onClick={handleDisplayList}>Get Crypto List</Button>
                    </Center>
                    
                    {showList && cryptoIdsList && (
                        <Box mt={2}>
                            <Text>Request URL</Text>
                            <Input isReadOnly={true} value={cryptoIdsList.url} />
                            <Text mt='8px'>Result</Text>
                            <Input isReadOnly={true} value={JSON.stringify(cryptoIdsList.data, null, 1)} />
                        </Box>
                    )}
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
                <h2>
                    <AccordionButton>
                        <Center flex="1">
                            Get Fear&Greed data
                        </Center>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>

                    <Center>
                        <Tag size='md' colorScheme='red' borderRadius='full'>This endpoint require an authorization token</Tag>
                    </Center>
                    
                    <Text mt={4}>History range in days</Text>
                    <NumberInput min={1} value={inputs.fearInput} onChange={(value) => handleInputChange('fearInput', value)}>
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>

                    <Center mt={2}>
                        <Button onClick={handleFetchFear}>Get Fear&Greed data</Button>
                    </Center>
                    
                    { apiData.fearData && (
                        <Box mt={2}>
                            <Text>Request URL</Text>
                            <Input isReadOnly={true} value={apiData.fearData.url} />
                            <Text mt='8px'>Result</Text>
                            <Input isReadOnly={true} value={JSON.stringify(apiData.fearData.data, null, 1)} />
                        </Box>
                    )}
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
                <h2>
                    <AccordionButton>
                        <Center flex="1">
                            Get Bitcoin history data
                        </Center>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>

                    <Center>
                        <Tag size='md' colorScheme='red' borderRadius='full'>This endpoint require an authorization token</Tag>
                    </Center>
                    
                    <Text mt={4}>History range in days</Text>
                    <NumberInput min={1} value={inputs.btcInput} onChange={(value) => handleInputChange('btcInput', value)}>
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>

                    <Center mt={2}>
                        <Button onClick={handleFetchBtcHist}>Get Bitcoin history data</Button>
                    </Center>
                    
                    { apiData.btcData && (
                        <Box mt={2}>
                            <Text>Request URL</Text>
                            <Input isReadOnly={true} value={apiData.btcData.url} />
                            <Text mt='8px'>Result</Text>
                            <Input isReadOnly={true} value={JSON.stringify(apiData.btcData.data, null, 1)} />
                        </Box>
                    )}
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
                <h2>
                    <AccordionButton>
                        <Center flex="1">
                            Get Flag TVL history data
                        </Center>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>

                    <Center>
                        <Tag size='md' colorScheme='red' borderRadius='full'>This endpoint require an authorization token</Tag>
                    </Center>
                    
                    <Text mt={4}>History range in days</Text>
                    <NumberInput min={1} value={inputs.flagTvlInput} onChange={(value) => handleInputChange('flagTvlInput', value)}>
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>

                    <Center mt={2}>
                        <Button onClick={handleFetchFlagTvlHist}>Get Flag TVL history data</Button>
                    </Center>
                    
                    { apiData.flagTvlData && (
                        <Box mt={2}>
                            <Text>Request URL</Text>
                            <Input isReadOnly={true} value={apiData.flagTvlData.url} />
                            <Text mt='8px'>Result</Text>
                            <Input isReadOnly={true} value={JSON.stringify(apiData.flagTvlData.data, null, 1)} />
                        </Box>
                    )}
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
                <h2>
                    <AccordionButton>
                        <Center flex="1">
                            Get Flag stakers variation
                        </Center>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>

                    <Center>
                        <Tag size='md' colorScheme='red' borderRadius='full'>This endpoint require an authorization token</Tag>
                    </Center>

                    <Center mt={2}>
                        <Button onClick={handleFetchFlagStakers}>Get Flag stakers variation</Button>
                    </Center>
                    
                    { apiData.flagStakersData && (
                        <Box mt={2}>
                            <Text>Request URL</Text>
                            <Input isReadOnly={true} value={apiData.flagStakersData.url} />
                            <Text mt='8px'>Result</Text>
                            <Input isReadOnly={true} value={JSON.stringify(apiData.flagStakersData.data, null, 1)} />
                        </Box>
                    )}
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
                <h2>
                    <AccordionButton>
                        <Center flex="1">
                            Get Flag token repartition
                        </Center>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>

                    <Center>
                        <Tag size='md' colorScheme='red' borderRadius='full'>This endpoint require an authorization token</Tag>
                    </Center>

                    <Center mt={2}>
                        <Button onClick={handleFetchFlagRepartition}>Get Flag token repartition</Button>
                    </Center>
                    
                    { apiData.flagRepartitionData && (
                        <Box mt={2}>
                            <Text>Request URL</Text>
                            <Input isReadOnly={true} value={apiData.flagRepartitionData.url} />
                            <Text mt='8px'>Result</Text>
                            <Input isReadOnly={true} value={JSON.stringify(apiData.flagRepartitionData.data, null, 1)} />
                        </Box>
                    )}
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );
};
