import { Box, Center, useColorMode, useToast, Stack, Button, Divider, Text, IconButton, SimpleGrid, Badge } from '@chakra-ui/react'
import { TabsFunction } from '../../../components/page-content/TabsFunction';
import { SkipNavLink, SkipNavContent } from '@chakra-ui/skip-nav';
import { ScrollToTopButton } from '../../../components/button/ScrollToTopButton'
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark, solarizedlight } from "react-syntax-highlighter/dist/esm/styles/prism";
import { MdContentCopy } from "react-icons/md";
import { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import 'github-markdown-css/github-markdown.css';
import { codeMonitoring, codeBackup } from '../../../components/page-content/codepart/CodePartOfPyScript';

export const PyScripts = () => {

    const [readmeContent, setReadmeContent] = useState('');
    const { colorMode } = useColorMode();
    const toast = useToast({
        position: 'top',
    });

    useEffect(() => {
        fetch('https://raw.githubusercontent.com/dyfault-eth/py-monitoring/main/README.md')
          .then(response => response.text())
          .then(text => setReadmeContent(text))
          .catch(error => console.error(error));
    }, []);

    const handleCopyClick = (text) => {
        navigator.clipboard.writeText(text);
        toast({
            title: "Success",
            description: "copied to clipboard",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
    };

    const backupCron = `0 8 * * * python3 /root/py-get-backup/main.py >> /root/py-get-backup/log/log.txt 2>&1`

    const backupLog = `16-07-2023
Download backup-system_16-07-2023.tar.gz to /root/py-get-backup/backup/rpi/backup-system_16-07-2023.tar.gz
Downloading: 100%|███████████████████████████████| 3.25G/3.25G [36:45, 1.47MB/s]
Download backup_16-07-2023.tar.gz to /root/py-get-backup/backup/vps/backup_16-07-2023.tar.gz
Downloading: 100%|███████████████████████████████| 2.90G/2.90G [12:04, 4.00MB/s]`

    const contentMonitoring =
    <Box>

        <Stack>
            <Center mt='12px' display="flex" alignItems='center'>
                You can check the project on{" "}
                
                <Button variant="urlbutton" marginLeft="-0.6rem" as="a" href="https://github.com/dyfault-eth/py-monitoring" target="_blank" rel="noreferrer">my github</Button>
            </Center>
            <Center>
                <Divider w={['350px', null, '650px', '750px', '850px', '950px']}/ >
            </Center>

            <Center flex="1" flexDirection='column'>
                Libraries :
                
                <SimpleGrid columns={[3, null, 5]} mt='6px' gap={2}>
                    <Badge colorScheme="green" variant='solid'>os</Badge>
                    <Badge colorScheme="green" variant='solid'>subprocess</Badge>
                    <Badge colorScheme="green" variant='solid'>smtplib</Badge>
                    <Badge colorScheme="green" variant='solid'>ssl</Badge>
                    <Badge colorScheme="green" variant='solid'>dotenv</Badge>
                </SimpleGrid>
            </Center>
            
            <Center>
                <Divider w='850px' />
            </Center>
        </Stack>

        <Center>
            <SimpleGrid mt='24px'>
                <Button as={SkipNavLink} id='code' href='code' variant='skipnavbutton'>Skip to code</Button>
            </SimpleGrid>
        </Center>
        

        <Stack mt='16px'>
            <Box className='markdown-body' textAlign='left' bg={colorMode === 'dark' ? '#1a202c' : 'rgb(255, 255, 255)'} color={colorMode === 'dark' ? 'gray.200' : 'gray.500'}>
                <ReactMarkdown>{readmeContent}</ReactMarkdown>
            </Box>
        </Stack>

        <SkipNavContent id='code' />

        <Center>
            <Divider w={['350px', null, '650px', '750px', '850px', '950px']} mt='32px' / >
        </Center>

        <Box position='relative'>
            <Center mt='24px'>
                Example of crypto-prices Bash script :
            </Center>

            <SyntaxHighlighter language="python" style={colorMode === 'dark' ? atomDark : solarizedlight} customStyle={{fontSize: '14px'}}>
                {codeMonitoring}
            </SyntaxHighlighter>
            <IconButton onClick={() => handleCopyClick(codeMonitoring)} position="absolute" top="40px" right="8px" size="sm" icon={<MdContentCopy />} />
        </Box>
    </Box>

    const contentBackup = 
    <Box>

        <Stack>
            <Center mt='12px' display="flex" alignItems='center'>
                You can check the project on{" "}
                
                <Button variant="urlbutton" marginLeft="-0.6rem" as="a" href="https://github.com/dyfault-eth/get-backup-sftp" target="_blank" rel="noreferrer">my github</Button>
            </Center>
            <Center>
                <Divider w={['350px', null, '650px', '750px', '850px', '950px']} / >
            </Center>

            <Center flex="1" flexDirection='column'>
                Libraries :
                
                <SimpleGrid columns={[3, null, 3, 6]} mt='6px' gap={2}>
                    <Badge colorScheme="green" variant='solid'>paramiko</Badge>
                    <Badge colorScheme="green" variant='solid'>os</Badge>
                    <Badge colorScheme="green" variant='solid'>tqdm</Badge>
                    <Badge colorScheme="green" variant='solid'>datetime</Badge>
                    <Badge colorScheme="green" variant='solid'>functools</Badge>
                    <Badge colorScheme="green" variant='solid'>dotenv</Badge>
                </SimpleGrid>
            </Center>
            
            <Center>
                <Divider w='850px' />
            </Center>
        </Stack>

        <Box mt='16px'>
            <Text>Project presentation : </Text>

            <Text mt='16px'>
                I came up with the idea for this script after creating bash scripts on each server to back up my servers. 
                However, these backups were only saved locally on each server, and I had to retrieve them manually. 
                So, I developed this small project to schedule a script to run at a specific time. 
                The script creates an SFTP connection with a dedicated user who has restricted access to their directory and no shell access with SSH.
                This script logs the download progress in a log file, and there's another log file for Paramiko, which records the connection logs.
            </Text>
        </Box>

        <Center>
            <SimpleGrid mt='24px'>
                <Button as={SkipNavLink} id='code' href='code' variant='skipnavbutton'>Skip to code</Button>
            </SimpleGrid>
        </Center>

        <Center>
            <Divider w={['350px', null, '650px', '750px', '850px', '950px']} mt='32px' / >
        </Center>

        <Box position='relative'>
            <Center mt='24px'>
                line in the crontab file :
            </Center>

            <SyntaxHighlighter language="bash" style={colorMode === 'dark' ? atomDark : solarizedlight} customStyle={{fontSize: '14px'}}>
                {backupCron}
            </SyntaxHighlighter>
            <IconButton onClick={() => handleCopyClick(backupCron)} position="absolute" top="40px" right="8px" size="sm" icon={<MdContentCopy />} />
        </Box>

        <Center>
            <Divider w={['350px', null, '650px', '750px', '850px', '950px']} mt='32px' / >
        </Center>

        <Box position='relative'>
            <Center mt='24px'>
                Download log example :
            </Center>

            <SyntaxHighlighter language="" style={colorMode === 'dark' ? atomDark : solarizedlight} customStyle={{fontSize: '14px'}}>
                {backupLog}
            </SyntaxHighlighter>
            <IconButton onClick={() => handleCopyClick(backupLog)} position="absolute" top="40px" right="8px" size="sm" icon={<MdContentCopy />} />
        </Box>

        <SkipNavContent id='code' />

        <Center>
            <Divider w={['350px', null, '650px', '750px', '850px', '950px']} mt='36px' / >
        </Center>

        <Box position='relative'>
            <Center mt='34px'>
                code of the project :
            </Center>

            <SyntaxHighlighter language="python" style={colorMode === 'dark' ? atomDark : solarizedlight} customStyle={{fontSize: '14px'}}>
                {codeBackup}
            </SyntaxHighlighter>
            <IconButton onClick={() => handleCopyClick(codeBackup)} position="absolute" top="40px" right="8px" size="sm" icon={<MdContentCopy />} />
        </Box>
    </Box>

    const tabs = [
    { label: "Download backup", content: contentBackup },
    { label: "Monitoring", content: contentMonitoring },
    ];

    return(
        <Center>
            <Box w={['375px', null, '650px', '750px', '1000px', '1250px']}>

                <Stack>
                    <Center>
                        <Text className="font-2" fontSize='24px'>Python Scripts</Text>
                    </Center>
                </Stack>

                <Box mt='12px'>
                    <TabsFunction tabs={tabs} />
                </Box>

            </Box>
            

            <ScrollToTopButton />
        </Center>
    )
}